import { all, put, takeLatest, select } from 'redux-saga/effects';
import signUp from '../services/api/actions/signUp';
import addBillAddress from '../services/api/actions/addBillAddress';
import changeProfileInfo from '../services/api/actions/changeProfileInfo';
import { selectEntityUserDetails } from '../store/selectors/entities';
import { setLoader } from '../store/actions';

function* createAddressWorker(action) {
  const userDetails = yield select(selectEntityUserDetails);

  if (userDetails?.billingAddresses?.some((item) => item.primary)) {
    return;
  }

  const {
    firstName,
    lastName,
    country,
    city,
    address,
    zipCode,
    phone,
    state,
  } = action.meta.previousAction.payload.request.data;

  const data = {
    firstName,
    lastName,
    country: country.toUpperCase(),
    countryCode: country.toUpperCase(),
    city,
    address,
    zipCode,
    phone,
    primary: true,
    state,
  };
  yield put(addBillAddress.action(data));
  yield put(setLoader(false));
}

export default function* autoCheckoutSage() {
  yield all([
    yield takeLatest([signUp.type.success, changeProfileInfo.type.success], createAddressWorker),
  ]);
}
