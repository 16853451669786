import { all, call, cancel, delay, fork, put, select, take, takeLatest } from 'redux-saga/effects';
import { getAction, isClient, isJson } from '../helpers/utils';
// eslint-disable-next-line import/no-cycle
import changeProfileInfo from '../services/api/actions/changeProfileInfo';
import getBillingDetails from '../services/api/actions/getBillingDetails';
import getCoupons from '../services/api/actions/getCoupons';
import getUserDetails from '../services/api/actions/getUserDetails';
import getUserPaymentMethods from '../services/api/actions/getUserPaymentMethods';
import getUserSubscriptions from '../services/api/actions/getUserSubscriptions';
import getWallets from '../services/api/actions/getWallets';
import validateEmail from '../services/api/actions/validateEmail';
import { updateSiteData } from './main';

import { LOG_OUT_USER, STOP_TASK, UPDATE_USER_DATA } from '../store/constants';

import {
  setLanguages,
  setLoader,
  setModal,
  setModalError,
  toggleEmailCheckBanner,
} from '../store/actions';

import {
  selectEntityBillingDetails,
  selectEntityUserDetails,
  selectEntityUserSubscriptions,
} from '../store/selectors/entities';

import { createNavigateTo, pageLinks } from '../helpers/navigation';
import setNotification from '../helpers/notifications';
import addAgreement from '../services/api/actions/addAgreement';
import addBillAddress from '../services/api/actions/addBillAddress';
import calculatePrice from '../services/api/actions/calculatePrice';
import changeEmail from '../services/api/actions/changeEmail';
import getUserElectronicSign from '../services/api/actions/getUserElectronicSign';
import getUserSocialNetworkLinks from '../services/api/actions/getUserSocialNetworkLinks';
import signUp from '../services/api/actions/signUp';
import * as Constants from '../store/constants';
import { selectIbpProduct } from '../store/selectors';

const isUserLogged = () => localStorage.getItem('AT');

let counter = 0;
let firstTimeFlag = true;
let requestData = null;
let emailBannerShowed = false;

// TODO: retry saga one function for every request
function* retryDetailsSaga(action) {
  if (action.error.status === 0) {
    return;
  }

  const statusCode = action?.error?.response?.data?.statusCode;
  if (statusCode === 401) {
    return;
  }
  yield delay(1500);
  yield put(getUserDetails.action());
}

function* retrySaga(action) {
  const { statusCode, message } = action.error && action.error.response.data;
  if (statusCode === 401) {
    return;
  }

  const addressError =
    message.includes('is invalid') ||
    message.includes('postalCode') ||
    message.includes('is not valid');
  if (addressError) {
    counter = 0;
    return;
  }

  if (statusCode === 409) {
    yield put(setLoader(false));
    setNotification('error', {
      message,
      title: 'Error',
    });
    counter = 0;
    return;
  }

  if (statusCode !== 401 && counter <= 18) {
    yield delay(1500);
    counter += 1;
    yield put(getBillingDetails.action());
  }
  if (counter === 18) {
    counter = 20;
    yield put(setLoader(false));
    setNotification('error', {
      message: message || 'Something went wrong, please reload page or train again later',
      title: 'Error',
    });
  }
}

function* updateAgreementData() {
  yield put(setLoader(false));

  const subscriptions = yield select(selectEntityUserSubscriptions);

  if (!subscriptions) {
    return;
  }

  const ibiProduct = yield select(selectIbpProduct);

  let items = [];

  if (!items.length) {
    const ibiItem = {
      planId: ibiProduct?.id || 9,
      action: getAction(ibiProduct?.purchased || ibiProduct?.isRenew),
    };
    items = [ibiItem];
  }

  const totalAmountForInitialItems = items.filter((item) => item.action === 'INITIAL')?.length || 0;

  yield put(
    getUserElectronicSign
      .withQuery('?type=signature_ibi')
      .action({ items, totalAmountForInitialItems })
  );
}

function* getUserDataBg() {
  yield put(setModalError(null));
  yield put(setModal(null));
  yield put(getBillingDetails.action());
  yield put(getUserSocialNetworkLinks.action());
  yield put(getUserSocialNetworkLinks.action());
  yield put(getUserPaymentMethods.action());
  yield put(getUserSubscriptions.action());
  yield put(getCoupons.action());
  yield put(getWallets.action());
  yield put(setLanguages(['en', 'de', 'fr', 'pt', 'es', 'zh']));
  yield put({ type: 'STOP_WORKER' });
}

function* getUserData() {
  if (isClient && isUserLogged()) {
    const storagePersistData = window.localStorage.getItem('persist:entities');
    const details = isJson(storagePersistData) && JSON.parse(storagePersistData)?.userDetails;
    const customerId = isJson(details) && JSON.parse(details) && JSON.parse(details)?.customerId;

    if (!details || !customerId) {
      yield put(setLoader(true));
    }

    yield delay(0);

    yield all([put(getUserDetails.action()), put(getBillingDetails.action())]);

    while (true) {
      const request = yield take(getBillingDetails.type.success);
      if (request) {
        yield put(getUserPaymentMethods.action());
      }
      const request1 = yield take(getUserPaymentMethods.type.success);
      if (request1) {
        yield all([
          put(getUserSubscriptions.action()),
          put(getCoupons.action()),
          put(getWallets.action()),
        ]);

        yield put(setLoader(false));
        yield put(getUserSocialNetworkLinks.action());
        yield put(setLanguages(['en', 'de', 'fr', 'pt', 'es', 'zh']));

        const urlsToNotClean = window.location.pathname.includes(pageLinks.checkoutSuccess);
        if (!urlsToNotClean) {
          window.history.replaceState({}, document.title, window.location.pathname);
        }

        yield call(updateAgreementData);

        yield put({ type: STOP_TASK });
      }
    }
  }
}

function* checkEmail({ payload }) {
  const { emailVerified, email, customerId } = payload.data.data;

  yield call(updateSiteData, { customerId });

  if (!emailVerified) {
    yield put(validateEmail.withQuery(`/?email=${email}`).action());
    while (!emailBannerShowed) {
      const request = yield take([validateEmail.type.success, validateEmail.type.error]);
      if (request.error) {
        yield put(toggleEmailCheckBanner(true));
        emailBannerShowed = true;
      }
      if (request.payload && !emailBannerShowed) {
        yield put(changeProfileInfo.action({ ...payload.data.data, emailVerified: true }));

        const errorResponse = yield take(changeProfileInfo.type.error);

        //! retry users data if 409 received before
        const isBillDetailsPresent = yield select(selectEntityBillingDetails);

        yield put({ type: STOP_TASK });

        if (!isBillDetailsPresent && !errorResponse) {
          yield call(getUserData);
        }
      }
    }
  }
}

function* checkAddressSaga(action) {
  const { error } = action;
  const getBillDetailsMessage = error && error.response.data.message;

  const details = yield select(selectEntityUserDetails);

  const modalType =
    details && details.billingAddresses && details.billingAddresses.length
      ? 'editAddress'
      : 'newAddress';

  const addressError =
    getBillDetailsMessage &&
    (getBillDetailsMessage.includes('is invalid') || getBillDetailsMessage.includes('postalCode'));

  if (addressError && firstTimeFlag) {
    createNavigateTo(pageLinks.checkout)();
    firstTimeFlag = false;
    yield put(setLoader(false));

    if (!addressError) {
      yield put({ type: STOP_TASK });
    }

    yield put(setModal(modalType));
    yield put(
      setModalError(
        'The Address, City, or Postal code you provided is not valid. Please provide a valid one below'
      )
    );
  }
}

function* checkPaymentMethodSaga(action) {
  const details = action.payload.data.data;

  const billingAddresses =
    details && details.billingAddresses && details.billingAddresses.length > 0
      ? details.billingAddresses
      : null;

  const isPrimaryAddress = billingAddresses && !!billingAddresses.find((item) => !!item.primary);

  const modalType = isPrimaryAddress ? 'add' : 'newAddress';

  const { shouldAddPaymentMethod } = details;

  if (shouldAddPaymentMethod) {
    const body = document.querySelector('body');
    body.classList.add('modal-is-active');

    // createNavigateTo(pageLinks.checkout)();
    yield put(setLoader(false));

    yield put(setModal(modalType));
    yield put(
      setModalError(
        "We noticed that you have an active subscription and you didn't set your payment method yet\n" +
          'Kindly spend two minutes to update your billing details and set your default payment method in order' +
          ' to maintain your subscription active and auto renewal'
      )
    );
  }
}

function* checkAddressAndRetry(action) {
  const { payload } = action;
  yield put(getUserDetails.action());
  while (true) {
    const details = yield take(getUserDetails.type.success);
    if (details) {
      const { city, address, zipCode } = details.payload.data.data;
      if (payload.city === city && payload.address === address && payload.zipCode === zipCode) {
        yield put(setModalError(null));
        yield call(getUserDataBg);
      } else {
        yield delay(1000);
        yield put(getUserDetails.action());
      }
    }
  }
}

// USED TO SEND FULL DATA FOR SIFT
function* updatedUserDetailsWithFullData(action) {
  const actionData = action;
  const details = yield select(selectEntityUserDetails);
  const { dateOfIncorporation, ...rest } = details;
  const userDetails = {
    ...rest,
  };
  const fullData = {
    ...userDetails,
    ...actionData.payload.request.data,
  };
  actionData.payload.request.data = fullData;
}

function* cancelWorkerSaga(task) {
  yield cancel(task);
}

function* userDataSaga() {
  const task = yield fork(getUserData);

  yield all([
    // yield takeLatest(FETCH_REFFERIAL_INFO, fetchRefferalWorker),
    yield takeLatest(getUserDetails.type.error, retryDetailsSaga),
    yield takeLatest(getUserDetails.type.success, checkPaymentMethodSaga),
    yield takeLatest(changeProfileInfo.type.start, updatedUserDetailsWithFullData),
    yield takeLatest(
      [UPDATE_USER_DATA, changeEmail.type.success, signUp.type.success],
      getUserData
    ),
    yield takeLatest(getBillingDetails.type.error, retrySaga),
    yield takeLatest(LOG_OUT_USER, () => {
      firstTimeFlag = true;
    }),
    yield takeLatest(getUserDetails.type.success, checkEmail),
    yield takeLatest(
      [
        addAgreement.type.success,
        calculatePrice.type.success,
        addBillAddress.type.success,
        Constants.HANDLE_CHECK_AGREEMENT,
        getUserSubscriptions.type.success,
      ],
      updateAgreementData
    ),
    yield takeLatest([STOP_TASK, LOG_OUT_USER], () => cancelWorkerSaga(task)),
  ]);

  const action = yield take(getBillingDetails.type.error);
  const { statusCode } = action.error && action.error.response.data;

  while (action && statusCode !== 409) {
    const checkTask = yield fork(checkAddressSaga, action);
    const response = yield take(changeProfileInfo.type.success);
    requestData = response.meta.previousAction.payload.request.data;
    const worker = yield fork(checkAddressAndRetry, { payload: requestData });
    yield take('STOP_WORKER');
    yield cancel(checkTask);
    yield cancel(worker);
  }
}

export default userDataSaga;
