const prefix = 'APP/';

export const ADD_TO_CART = `${prefix}ADD_TO_CART`;
export const START_SSO = `${prefix}START_SSO`;
export const SELECT_PRODUCT = `${prefix}SELECT_PRODUCT`;
export const CHANGE_USER_DETAILS = `${prefix}CHANGE_USER_DETAILS`;
export const VALIDATE_AND_ADD_TO_CART = `${prefix}VALIDATE_AND_ADD_TO_CART`;
export const REMOVE_FROM_CART = `${prefix}REMOVE_FROM_CART`;
export const CLEAR_SHOPPING_CART = `${prefix}CLEAR_SHOPPING_CART`;
export const SELECT_COUPON = `${prefix}SELECT_COUPON`;
export const SELECT_PAYMENT_METHOD_FROM_SAVED = `${prefix}SELECT_PAYMENT_METHOD_FROM_SAVED`;
export const CHECK_COUPON = `${prefix}CHECK_COUPON`;
export const PRESELECTED_COUPON = `${prefix}PRESELECTED_COUPON`;
export const SET_LOADER = `${prefix}SET_LOADER`;
export const SET_LANGUAGES = `${prefix}SET_LANGUAGES`;
export const LOG_OUT_USER = `${prefix}LOG_OUT_USER`;
export const SET_PRODUCTS = `${prefix}SET_PRODUCTS`;
export const MAKE_ORDER_WITH_SAVED_CART = `${prefix}MAKE_ORDER_WITH_SAVED_CART`;
export const MAKE_ORDER_WITH_D_LOCAL_INSTALLMENT = `${prefix}MAKE_ORDER_WITH_D_LOCAL_INSTALLMENT`;
export const FETCH_REFFERIAL_INFO = `${prefix}FETCH_REFFERIAL_INFO`;
export const VALIDATE_ADDON = `${prefix}VALIDATE_ADDON`;
export const SET_BANNER_REFFERAL = `${prefix}SET_BANNER_REFFERAL`;
export const SHOW_REFERRALS_HEADER = `${prefix}SHOW_REFERRALS_HEADER`;
export const STOP_TASK = `${prefix}STOP_TASK`;
export const REGISTER_USER = `${prefix}REGISTER_USER`;
export const REGISTER_USER_AFTER_PHONE_VALIDATION = `${prefix}REGISTER_USER_AFTER_PHONE_VALIDATION`;
export const UPDATE_USER_DATA = `${prefix}UPDATE_USER_DATA`;
export const SET_ADDONS_MODAL_INFO = `${prefix}SET_ADDONS_MODAL_INFO`;
export const EMULATE_CREATION_DEFAULT_CARD = `${prefix}EMULATE_CREATION_DEFAULT_CARD`;
export const UPDATE_CARD_INFO = `${prefix}UPDATE_CARD_INFO`;
export const SET_MODAL = `${prefix}SET_MODAL`;
export const SET_MODAL_ERROR = `${prefix}SET_MODAL_ERROR`;
export const TOGGLE_CHECK_EMAIL_BANNER = `${prefix}TOGGLE_CHECK_EMAIL_BANNER`;
export const SAVE_REFERRAL_INFO = `${prefix}SAVE_REFERRAL_INFO`;
export const SET_EXPECTED_SUBSCRIPTIONS = `${prefix}SET_EXPECTED_SUBSCRIPTIONS`;
export const CLEAR_USER_DATA_ON_NEW_ENROLLMENT_INFO = `${prefix}CLEAR_USER_DATA_ON_NEW_ENROLLMENT_INFO`;
export const CHANGE_LANGUAGE = `${prefix}CHANGE_LANGUAGE`;
export const SET_USER_COUNTRY = `${prefix}SET_USER_COUNTRY`;
export const CREATE_PAYMENT_ERROR = `${prefix}CREATE_PAYMENT_ERROR`;
export const HIDE_ADDON_PANEL = `${prefix}HIDE_ADDON_PANEL`;
export const START_REDIRECTION = `${prefix}START_REDIRECTION`;
export const SET_SUBMIT_COUNT = `${prefix}SET_SUBMIT_COUNT`;
export const CLEAR_SELECTED_COUPONS = `${prefix}CLEAR_SELECTED_COUPONS`;
export const RECALCULATE_SHOPPING_CART_PRICE = `${prefix}RECALCULATE_SHOPPING_CART_PRICE`;
export const UPDATE_PRODUCT_LIST = `${prefix}UPDATE_PRODUCT_LIST`;
export const TOGGLE_ANNUAL = `${prefix}TOGGLE_ANNUAL`;
export const UPDATE_PAYMENT_METHODS = `${prefix}UPDATE_PAYMENT_METHODS`;
export const SET_BUTTON_LOADER = `${prefix}SET_BUTTON_LOADER`;
export const TOGGLE_LIVE_CHAT = `${prefix}TOGGLE_LIVE_CHAT`;
export const SHOW_SKIP_VERIFICATION_BUTTON = `${prefix}SHOW_SKIP_VERIFICATION_BUTTON`;
export const SET_AUDIT_TRIAL = `${prefix}SET_AUDIT_TRIAL`;
export const SET_AUDIT_TRAIL_STATUS = `${prefix}SET_AUDIT_TRAIL_STATUS`;
export const SET_PAYMENT_METHODS = `${prefix}SET_PAYMENT_METHODS`;
export const UPDATE_PRODUCTS = `${prefix}UPDATE_PRODUCTS`;
export const SET_COOKIES_BANNER = `${prefix}SET_COOKIES_BANNER`;

export const GET_SIGNED_AGREEMENT = `${prefix}GET_SIGNED_AGREEMENT`;
export const SET_AGREEMENT_DATA_FLAG = `${prefix}SET_AGREEMENT_DATA_FLAG`;
export const HANDLE_CHECK_AGREEMENT = `${prefix}HANDLE_CHECK_AGREEMENT`;
export const PUT_SOCIAL_LINKS = `${prefix}PUT_SOCIAL_LINKS`;
