import { all, call, delay, put, select, take, takeLatest } from 'redux-saga/effects';
import { createNavigateTo, pageLinks } from '../helpers/navigation';
import addPaymentCard from '../services/api/actions/addPaymentCard';
import addPaymentCardAndSetDefault from '../services/api/actions/addPaymentCardAndSetDefault';
import getUserElectronicSign from '../services/api/actions/getUserElectronicSign';
import ordersPurchase from '../services/api/actions/ordersPurchase';
import removePaymentMethod from '../services/api/actions/removePaymentMethod';
import setPaymentAsDefault from '../services/api/actions/setPaymentAsDefault';
import {
  clearShoppingCart,
  getSignedAgreement,
  makeOrderWithSavedCart,
  selectCoupon,
  selectPaymentMethodFromSaved,
  setAvailablePaymentMethods,
  setButtonLoaderType,
  setLoader,
  setModal,
  setModalError,
  setSubmitCount,
  toggleAgreementDataFlag,
  validateAddon,
} from '../store/actions';
import {
  selectBillingAddresses,
  selectCart,
  selectIbiGateways,
  selectIbpProduct,
  selectPaymentsWithDefault,
  selectSetPaymentMethod,
} from '../store/selectors';
import {
  selectCalculatedPrice,
  selectEntityPaymentMethods,
  selectEntityUserDetails,
  selectMetaSiteInfo,
} from '../store/selectors/entities';
import {
  selectChosenPaymentMethod,
  selectCouponId,
  selectShouldUpdateAgreement,
  selectSubmitCount,
} from '../store/selectors/global';

import {
  ADD_TO_CART,
  CLEAR_SELECTED_COUPONS,
  EMULATE_CREATION_DEFAULT_CARD,
  MAKE_ORDER_WITH_D_LOCAL_INSTALLMENT,
  MAKE_ORDER_WITH_SAVED_CART,
  REMOVE_FROM_CART,
  SELECT_COUPON,
  SELECT_PAYMENT_METHOD_FROM_SAVED,
  UPDATE_CARD_INFO,
  UPDATE_PAYMENT_METHODS,
} from '../store/constants';

import {
  convertToShopPaymentMethod,
  getAction,
  getMonthByNumber,
  getPaymentsByCountry,
  getPaymentTitle,
  isAddonInstance,
  isClient,
  isUserLogged,
  SHOPS_METHODS,
  transformCartToItems,
} from '../helpers/utils';

import { ButtonLoaderTypes, PAYPAL_GETAWAYS } from '../constants/types';
import { getCountryData } from '../helpers/countries';
import setNotification from '../helpers/notifications';
import { checkSitePage, getSearchParam } from '../helpers/url';
import addBillAddress from '../services/api/actions/addBillAddress';
import calculatePrice from '../services/api/actions/calculatePrice';
import createPayment from '../services/api/actions/createPayment';
import getBillingDetails from '../services/api/actions/getBillingDetails';
import getCoupons from '../services/api/actions/getCoupons';
import getGatewayMethods from '../services/api/actions/getGatewayMethods';
import getUserDetails from '../services/api/actions/getUserDetails';
import getUserPaymentMethods from '../services/api/actions/getUserPaymentMethods';
import updatePaymentCard from '../services/api/actions/updatePaymentCard';
import updateUserCurrency from '../services/api/actions/updateUserCurrency';

function* prepareDataForOrder(action) {
  yield put(setLoader(true));
  const submitCount = yield select(selectSubmitCount);
  yield put(setSubmitCount(1));

  if (submitCount) {
    return;
  }

  const { products } = yield select(selectCart);
  const selectedPaymentMethod = yield select(selectChosenPaymentMethod);
  const itemsForRequest = transformCartToItems(products, false);
  const selectedCoupon = yield select(selectCouponId);
  const calculatedPrice = yield select(selectCalculatedPrice);
  const total = calculatedPrice?.totalAmount;
  const id = localStorage.getItem('audit-trial-id');
  const redirectUrl = `${window.location.origin}/checkout-result/?complete={result}`;
  const meta = yield select(selectMetaSiteInfo);
  const userDetails = yield select(selectEntityUserDetails);

  const paymentInstrumentId = selectedPaymentMethod?.id || null;
  const paymentMethod = selectedPaymentMethod?.method || null;
  const couponInfo = selectedCoupon ? { couponCode: selectedCoupon } : {};

  const paymentInfoId = paymentInstrumentId
    ? { paymentInstrumentId, redirectUrl }
    : { redirectUrl };

  const bitcoinMethod = paymentMethod === 'bitpay' || paymentMethod === 'coinpayments';

  const paymentInfoMethod = paymentMethod
    ? { paymentMethod: bitcoinMethod ? 'bitcoin' : paymentMethod }
    : {};

  let gatewayAccountId = bitcoinMethod ? { gatewayAccountId: paymentMethod } : {};

  //! ON D_LOCAL SUBMIT CLICK
  if (action.type === MAKE_ORDER_WITH_D_LOCAL_INSTALLMENT) {
    gatewayAccountId = {
      gatewayAccountId: action.payload.gatewayAccountId,
      paymentInstrumentId: action.payload.paymentInstrumentId,
    };
  }

  if (paymentMethod === 'astroPay') {
    paymentInfoMethod.paymentMethod = 'AstroPay Card';
  }

  if (paymentMethod === 'coinpayments') {
    paymentInfoMethod.paymentMethod = undefined;
  }

  if (paymentMethod === 'paypal-card') {
    gatewayAccountId = { gatewayAccountId: PAYPAL_GETAWAYS.paypalCard };
    paymentInfoMethod.paymentMethod = 'paypal';
  }

  if (paymentMethod === 'paypal') {
    gatewayAccountId = { gatewayAccountId: PAYPAL_GETAWAYS.paypalCommon };
    paymentInfoMethod.paymentMethod = 'paypal';
  }

  if (total === 0 && !paymentMethod) {
    paymentInfoMethod.paymentMethod = 'e-wallet';
  }

  const paymentData = { ...paymentInfoId, ...paymentInfoMethod };

  const autopay =
    selectedPaymentMethod?.method === 'payment-card' || selectedPaymentMethod?.method === 'paypal';

  const autopayFlag =
    autopay || (!paymentData.paymentMethod && !paymentData.paymentInstrumentId) || false;

  const data = {
    ...paymentData,
    ...couponInfo,
    ...gatewayAccountId,
    autopay: !!autopayFlag,
    items: itemsForRequest,
    signingExternalId: id,
  };

  try {
    const selectedMethodSupportedCurrencies =
      meta?.paymentMethodCurrencies?.find((item) => item?.name === paymentInfoMethod?.paymentMethod)
        ?.currencies || [];

    const userCurrency = userDetails?.preferredCurrency?.code;

    if (!selectedMethodSupportedCurrencies.includes(userCurrency)) {
      const nextCurrency = selectedMethodSupportedCurrencies[0] || 'USD';
      const response = yield all([
        yield put(updateUserCurrency.action({ currencyCode: nextCurrency })),
      ]);
    }
  } catch (e) {
    console.error('error', e);
  } finally {
    yield put(ordersPurchase.action(data));
  }
}

function* onSuccessPayment(action) {
  try {
    const {
      approvalUrl,
      items,
      status,
      amount,
      amountFee,
      amountVat,
      paymentInstrumentId,
      fromEWalletAmount,
      result,
      orderId,
      transactions,
    } = action.payload.data;

    const paymentMethod = transactions?.[0]?.paymentMethod;
    const transactionId = transactions?.[0]?.id;

    const savedPaymMethods = yield select(selectEntityPaymentMethods);
    const userDetails = yield select(selectEntityUserDetails);
    const billAddresses = yield select(selectBillingAddresses);

    let primaryAddress = billAddresses.find((item) => !!item.primary);

    let successPayment = paymentMethod;

    if (paymentMethod === 'payment-card') {
      const methodList = savedPaymMethods.cards;
      const method = methodList.find((item) => item.id === paymentInstrumentId);
      successPayment = method.last4
        ? `${paymentMethod} ${method.brand} *${method.last4}`
        : paymentMethod;
      primaryAddress = method.billingAddress;
      const phone =
        method.billingAddress.phoneNumbers[0] && method.billingAddress.phoneNumbers[0].value;
      if (phone) {
        primaryAddress.phone = phone;
      }
    }

    const date = new Date();
    if (isClient) {
      const successPageInfo = {
        userName: localStorage.getItem('UN'),
        exigoId: null,
        firstName: primaryAddress.firstName,
        lastName: primaryAddress.lastName,
        customerId: userDetails.customerId,
        email: userDetails.email,
        phone: primaryAddress.phone,
        order: orderId,
        date: `${getMonthByNumber(date.getMonth())} ${date.getUTCDate()}, ${date.getFullYear()}`,
        paymentMethod: successPayment,
        address: `${primaryAddress.country.toUpperCase()}, ${primaryAddress.state || ''} ${
          primaryAddress.address
        }, ${primaryAddress.zipCode || primaryAddress.postalCode}`,
        products: items,
        subtotal: amount,
        amountFee,
        amountVat,
        fromEWalletAmount,
      };

      localStorage.setItem('boughtProducts', JSON.stringify(successPageInfo));
      localStorage.removeItem('shoppingCart');
    }

    yield put(selectCoupon(null));
    yield put(setLoader(false));
    yield put(clearShoppingCart());
    yield put(selectPaymentMethodFromSaved(null));
    yield put(getCoupons.action());

    if (approvalUrl && status === 'waiting-approval') {
      window.location.href = approvalUrl;
    }

    if (!approvalUrl || status === 'completed') {
      const orderResult = result === 'approved' ? 'approved' : status;
      yield call(createNavigateTo(`${pageLinks.checkoutSuccess}/?complete=${orderResult}`));
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('catch', e);
  }
}

function* updatePaymentMethods(action) {
  const details = yield select(selectEntityUserDetails);

  if (details.shouldAddPaymentMethod) {
    yield put(setModalError(null));
    yield put(getUserDetails.action());
  }

  yield put(setLoader(false));

  yield put(getUserPaymentMethods.action());

  if (
    action.type === addPaymentCardAndSetDefault.type.success ||
    action.type === setPaymentAsDefault.type.success ||
    action.type === UPDATE_PAYMENT_METHODS ||
    action.type === addPaymentCard.type.success
  ) {
    yield put(getBillingDetails.action());
    yield put(setModal(null));
    document.body.className = document.body.className.replace('modal-is-active', '');
  }

  if (action.type === removePaymentMethod.type.success) {
    yield put(getBillingDetails.action());
  }

  if (action.type !== UPDATE_PAYMENT_METHODS) {
    setNotification('success', {
      message: 'Your payment data updated',
      title: 'Success',
    });
  }
}

function* hideModalAndSetSelected(action) {
  let paymentInstrumentId = action.payload.data;

  while (paymentInstrumentId) {
    const request = yield take(getUserPaymentMethods.type.success);
    if (request) {
      const { cards, paypal, bankAccounts } = request.payload.data;
      const card = cards.find((item) => item.id === action.payload.data.paymentInstrumentId);
      const paypalMethod = paypal.find(
        (item) => item.id === action.payload.data.paymentInstrumentId
      );

      const defaultBankAccounts =
        bankAccounts &&
        bankAccounts.find((item) => item.id === action.payload.data.paymentInstrumentId);

      if (card || paypal || defaultBankAccounts) {
        yield put(selectPaymentMethodFromSaved(card || paypalMethod || defaultBankAccounts));
        yield put(setModal(null));
        paymentInstrumentId = null;
        document.body.className = document.body.className.replace('modal-is-active', '');
      }
    }
  }
}

function* defaultPaymentMethodWorker() {
  try {
    const selectedPaymentMethod = yield select(selectChosenPaymentMethod);
    const { cards, paypal, bankAccounts } = yield select(selectPaymentsWithDefault);
    const ibiGateways = yield select(selectIbiGateways);

    const defaultMethod = [...cards, ...paypal, ...bankAccounts].find((item) => item.isDefault);

    if (
      defaultMethod &&
      defaultMethod.id !== selectedPaymentMethod?.id &&
      ibiGateways.includes(defaultMethod)
    ) {
      yield put(selectPaymentMethodFromSaved(defaultMethod));
    }
  } catch (e) {
    // eslint-disable-next-line
    console.warn('Error while setting default method', e);
  }
}

// eslint-disable-next-line require-yield
function* onCreatePaymentMethod(action) {
  if (isClient) window.location.href = action.payload.data.approvalUrl;
}

// TODO: refactor
function* updateCardInfo(action) {
  const selectedPayment = yield select(selectChosenPaymentMethod);
  const { bankAccounts } = yield select(selectPaymentsWithDefault);
  const {
    address,
    address2,
    phone,
    city,
    country,
    zipCode,
    primary,
    state,
    firstName,
    lastName,
  } = action.meta.previousAction.payload.request.data;

  const defaultPlaidMethod = bankAccounts.find((item) => !!item.isDefault) || null;

  if (action.type === addBillAddress.type.success && !primary) {
    return;
  }

  // Show notification on change address if method not supported
  const availablePayments = getPaymentsByCountry(country);

  const countryData = getCountryData(country);
  const countryName = countryData ? countryData.name : 'selected country';

  const updateMethod = (method) => (method === 'payment-card' ? 'card' : method);

  if (selectedPayment && !availablePayments.includes(updateMethod(selectedPayment.method))) {
    setNotification('info', {
      message: `${getPaymentTitle(
        selectedPayment.method
      )} is not available for ${countryName}. The payment method was changed to a default one.`,
      title: 'Info',
    });
    yield put(selectPaymentMethodFromSaved(null));
  }

  if (
    !selectedPayment &&
    defaultPlaidMethod &&
    !availablePayments.includes(defaultPlaidMethod.method)
  ) {
    setNotification('info', {
      message: `${getPaymentTitle(
        defaultPlaidMethod.method
      )} is not available for ${countryName}. The payment method was changed.`,
      title: 'Info',
    });
  }

  if (selectedPayment && selectedPayment.method === 'payment-card') {
    const cardData = {
      paymentInstrumentId: selectedPayment.id,
      expMonth: selectedPayment.expMonth,
      expYear: selectedPayment.expYear,
      billingAddress: {
        address,
        city,
        firstName,
        lastName,
        address2,
        phoneNumbers: [{ label: phone, value: phone, primary: true }],
        country: country.toUpperCase(),
        postalCode: zipCode,
        region: state || '',
      },
      stickyGatewayAccountId: selectedPayment.stickyGatewayAccountId,
    };

    yield put(updatePaymentCard.action(cardData));
    return;
  }

  if (!selectedPayment) {
    const methods = yield select(selectPaymentsWithDefault);

    const defaultCard =
      methods && methods.cards.length && methods.cards.find((item) => item.isDefault);
    if (defaultCard) {
      const cardData = {
        paymentInstrumentId: defaultCard.id,
        expMonth: defaultCard.expMonth,
        expYear: defaultCard.expYear,
        billingAddress: {
          address,
          city,
          address2,
          firstName,
          lastName,
          phoneNumbers: [{ label: phone, value: phone, primary: true }],
          country: country.toUpperCase(),
          postalCode: zipCode,
          region: state || '',
        },
        stickyGatewayAccountId: defaultCard.stickyGatewayAccountId,
      };

      yield put(updatePaymentCard.action(cardData));
    }
  }
}

function* updateSubmitCount() {
  yield put(setSubmitCount(0));
}

function* updateCvv(action) {
  const { id, cvv, expMonth, expYear } = action.payload;
  const cardData = {
    paymentInstrumentId: id,
    cvv,
    expMonth,
    expYear,
  };

  yield put(setLoader(true));

  const [responseAction] = yield all([yield put(updatePaymentCard.action(cardData))]);

  if (responseAction.type === updatePaymentCard.type.success) {
    yield put(setModal(null));

    if (isAddonInstance) {
      const { products } = yield select(selectCart);
      yield put(
        validateAddon(products.map((item) => ({ ...item, successActionType: 'PURCHASE' })))
      );
    } else {
      yield put(makeOrderWithSavedCart());
    }
  }

  if (responseAction.type === updatePaymentCard.type.error) {
    yield put(setLoader(null));
  }
}

function* createAndSetDefault(action) {
  const { method, paymentToken } = action.payload;
  yield put(addPaymentCard.action({ paymentToken }));

  while (true) {
    const request = yield take(addPaymentCard.type.success);
    if (request) {
      yield put(setPaymentAsDefault.action({ method, id: paymentToken }));
    }
  }
}

function* preparePurchaseDataWorker() {
  try {
    const { products } = yield select(selectCart);
    const ibiGateways = null;
    const tempToken = isClient && getSearchParam('tempTok', window.location.href);

    if (!isUserLogged() || !products.length || tempToken) {
      return;
    }

    let data;
    const selectedMethod = yield select(selectSetPaymentMethod);
    const calculatedPrice = yield select(selectCalculatedPrice);
    const total = calculatedPrice?.totalAmount;

    const itemsForRequest = yield transformCartToItems(products, false);
    const selectedCoupon = yield select(selectCouponId);
    const couponsExist = !!selectedCoupon?.filter(Boolean).length;
    const product = yield select(selectIbpProduct);

    data = {
      items: [
        {
          planId: product?.id || 9,
          action: getAction(product?.isRenew || product?.purchased),
        },
      ],
    };

    if (selectedMethod) {
      const paymentInstrumentId = selectedMethod.id;
      const paymentMethod = selectedMethod.method;

      const paymentInfoId = { paymentInstrumentId };

      const bitcoinMethod = paymentMethod === 'bitpay' || paymentMethod === 'coinpayments';

      const paymentInfoMethod = paymentMethod
        ? {
            paymentMethod: bitcoinMethod ? 'bitcoin' : paymentMethod,
          }
        : {};

      const gatewayAccountId = bitcoinMethod ? {} : {};

      if (paymentMethod === 'astroPay') {
        paymentInfoMethod.paymentMethod = 'AstroPay Card';
      }

      if (paymentMethod === 'paypal-card') {
        paymentInfoMethod.paymentMethod = 'paypal';
      }

      if (paymentMethod === 'coinpayments') {
        paymentInfoMethod.paymentMethod = undefined;
      }

      const isPrecheckOutPage = isClient && window.location.pathname.includes('pre-checkout');
      const isCheckOutPage = isClient && window.location.pathname.includes('checkout');
      const noPaymentmethodAndZeroPrice =
        isCheckOutPage && total === 0 && !ibiGateways?.includes(paymentMethod?.paymentMethod);

      if (isUserLogged() && (isPrecheckOutPage || noPaymentmethodAndZeroPrice)) {
        paymentInfoMethod.paymentMethod = 'e-wallet';
      }

      const paymentData = { ...paymentInfoId, ...paymentInfoMethod, ...gatewayAccountId };

      data = { ...data, ...paymentData };
    }

    // eslint-disable-next-line consistent-return
    return data;
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('calculate price error', e);
  }
}

export function* calculatePriceWorker() {
  if (isClient && window.location.pathname.includes(pageLinks.agreement)) {
    return;
  }
  yield delay(2000);
  const token = isClient && localStorage.getItem('AT');
  if (token && token !== 'null' && token.length > 5) {
    const orderData = yield call(preparePurchaseDataWorker);
    if (orderData) {
      yield put(calculatePrice.action(orderData));
    }
  }
}

function* showPurchaseButtonLoader() {
  yield put(setButtonLoaderType(ButtonLoaderTypes.PURCHASE));
}

function* hidePurchaseButtonLoader() {
  yield put(setButtonLoaderType(''));
}
function* processPaymentMethods() {
  if (isUserLogged()) {
    const shoppingCart = yield select(selectCart);
    const selectedPaymentMethod = yield select(selectChosenPaymentMethod);
    const shoppingCartItems = transformCartToItems(shoppingCart?.products);
    const allowedPaymentMethodsList = [SHOPS_METHODS.eWallet];
    let paymentMethodList = [];
    if (shoppingCartItems?.length) {
      const [getGatewayMethodsResponse] = yield all([
        yield put(getGatewayMethods.action({ items: shoppingCartItems })),
      ]);
      paymentMethodList = yield getGatewayMethodsResponse?.payload?.data?.data?.map(
        (pm) => pm.name
      );
    }

    const methods = yield call(convertToShopPaymentMethod, paymentMethodList);
    const allowedPaymentMethods = methods.filter((m) => allowedPaymentMethodsList.includes(m));

    yield put(setAvailablePaymentMethods(allowedPaymentMethods));
    if (selectedPaymentMethod && !allowedPaymentMethods.includes(selectedPaymentMethod.method)) {
      yield put(selectPaymentMethodFromSaved(null));
    }
  }
}

function* updatePdfAgreement() {
  yield delay(1000);
  const token = isClient && localStorage.getItem('AT');
  const validToken = token && token !== 'null' && token.length > 5;

  const shouldUpdateAgreement = yield select(selectShouldUpdateAgreement);
  const addresses = yield select(selectBillingAddresses);
  const withPrimary = addresses.some((adr) => adr?.primary);
  const { products, productCount } = yield select(selectCart);

  if (!validToken || !shouldUpdateAgreement || !withPrimary || !productCount) {
    return;
  }

  yield put(setLoader(false));

  while (true) {
    yield take(getUserElectronicSign.type.success);
    yield put(toggleAgreementDataFlag(false));
  }
}

// ! If user on the checkout page we updating agreement immediately
// ! in other cases we just change the flag and update agreement when user goes to the checkout page;
function* agreementFlagWorker() {
  yield put(toggleAgreementDataFlag(true));

  if (checkSitePage(pageLinks.checkout)) {
    yield put(getSignedAgreement());
  }
}

function* paymentSaga() {
  yield all([
    yield takeLatest(
      [
        addPaymentCardAndSetDefault.type.success,
        setPaymentAsDefault.type.success,
        addPaymentCard.type.success,
        removePaymentMethod.type.success,
        updatePaymentCard.type.success,
        UPDATE_PAYMENT_METHODS,
      ],
      updatePaymentMethods
    ),
    yield takeLatest(
      [ADD_TO_CART, REMOVE_FROM_CART, getUserDetails.type.success],
      processPaymentMethods
    ),
    yield takeLatest(
      [MAKE_ORDER_WITH_SAVED_CART, MAKE_ORDER_WITH_D_LOCAL_INSTALLMENT],
      prepareDataForOrder
    ),
    yield takeLatest(calculatePrice.type.success, hidePurchaseButtonLoader),
    yield takeLatest(
      [SELECT_COUPON, CLEAR_SELECTED_COUPONS, calculatePrice.type.start],
      showPurchaseButtonLoader
    ),
    yield takeLatest(ordersPurchase.type.success, onSuccessPayment),
    yield takeLatest([ordersPurchase.type.success, ordersPurchase.type.error], updateSubmitCount),
    yield takeLatest(createPayment.type.success, onCreatePaymentMethod),
    yield takeLatest(EMULATE_CREATION_DEFAULT_CARD, createAndSetDefault),
    yield takeLatest(UPDATE_CARD_INFO, updateCvv),
    yield takeLatest(
      [getBillingDetails.type.success, getUserPaymentMethods.type.success],
      defaultPaymentMethodWorker
    ),
    yield takeLatest(
      [
        addBillAddress.type.success,
        ADD_TO_CART,
        REMOVE_FROM_CART,
        SELECT_COUPON,
        CLEAR_SELECTED_COUPONS,
        SELECT_PAYMENT_METHOD_FROM_SAVED,
        updatePaymentCard.type.success,
      ],
      calculatePriceWorker
    ),
    yield takeLatest(
      [addPaymentCard.type.success, addPaymentCardAndSetDefault.type.success],
      hideModalAndSetSelected
    ),
  ]);
}

export default paymentSaga;
