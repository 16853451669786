import merge from 'lodash/merge';
import * as AppConstant from '../constants';
import getTokenFinal from '../../services/api/actions/getTokenFinal';
import signUp from '../../services/api/actions/signUp';
import { filterComboIncludedPlans } from '../../helpers/utils';

const initialState = {
  modalError: null,
  isLoading: false,
  isUserLogged: false,
  selectedCoupon: [],
  preselectedCoupon: null,
  userCountry: null,
  shoppingCart: {
    products: {},
    paymentAction: '',
  },
  languages: ['en', 'de', 'it', 'fr', 'pt', 'es', 'zh', 'ko', 'ru', 'ja'],
  selectedLanguage: 'en',
  selectedPaymentMethod: { method: 'e-wallet', id: null },
  modal: '',
  isWrongEmail: false,
  registrationValues: {},
  submitCount: 0,
  addonsModalInfo: { type: null },
  lastAddedAddon: null,
  isAnnually: false,
  buttonLoaderType: '',
  availablePaymentMethods: [],
  allowToSkipPhoneVerification: false,
  shouldUpdateAgreement: true,
  showCookiesBanner: true,
};

export default function reducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case AppConstant.SELECT_PAYMENT_METHOD_FROM_SAVED: {
      return { ...state, selectedPaymentMethod: payload };
    }
    case AppConstant.SET_LOADER: {
      return { ...state, isLoading: payload };
    }
    case AppConstant.SET_MODAL_ERROR: {
      return { ...state, modalError: payload };
    }
    case AppConstant.PRESELECTED_COUPON: {
      return { ...state, preselectedCoupon: payload };
    }
    case AppConstant.CHANGE_LANGUAGE: {
      return { ...state, selectedLanguage: payload };
    }
    case AppConstant.SELECT_COUPON: {
      return {
        ...state,
        selectedCoupon: [...state.selectedCoupon, payload],
      };
    }

    case AppConstant.LOG_OUT_USER: {
      return initialState;
    }

    case AppConstant.CLEAR_SELECTED_COUPONS: {
      return { ...state, selectedCoupon: [], preselectedCoupon: null };
    }

    case AppConstant.SET_ADDONS_MODAL_INFO: {
      return { ...state, addonsModalInfo: payload };
    }
    case AppConstant.SET_MODAL: {
      return { ...state, modal: payload };
    }

    case AppConstant.SET_USER_COUNTRY: {
      return { ...state, userCountry: payload };
    }
    case AppConstant.SET_BUTTON_LOADER: {
      return { ...state, buttonLoaderType: payload };
    }

    case AppConstant.ADD_TO_CART: {
      return {
        ...state,
        shoppingCart: { products: filterComboIncludedPlans(payload.data, state.shoppingCart) },
        // shoppingCart: { products: payload.data },
      };
    }

    case AppConstant.REMOVE_FROM_CART: {
      // TODO: need to be refactored
      const copiedCart = { ...state.shoppingCart };
      if (state.shoppingCart.products[payload]) {
        delete copiedCart.products[payload];
        return {
          ...state,
          shoppingCart: { products: copiedCart.products },
          selectedCoupon: [],
          preselectedCoupon: null,
        };
      }
      if (state.shoppingCart.services[payload]) {
        const services = delete copiedCart.services[payload];
        return merge({}, state, { shoppingCart: { services } });
      }
      return state;
    }
    case signUp.type.success:
    case getTokenFinal.type.success: {
      return { ...state, isUserLogged: true, registrationValues: {} };
    }

    case AppConstant.SET_LANGUAGES:
      return merge({}, state, { languages: payload });

    case AppConstant.TOGGLE_CHECK_EMAIL_BANNER:
      return { ...state, isWrongEmail: payload };

    case AppConstant.HIDE_ADDON_PANEL:
      return { ...state, lastAddedAddon: null };

    case AppConstant.REGISTER_USER:
      return { ...state, registrationValues: payload };

    case AppConstant.SET_SUBMIT_COUNT:
      return { ...state, submitCount: payload };

    case AppConstant.SET_PAYMENT_METHODS: {
      return { ...state, availablePaymentMethods: payload };
    }

    case AppConstant.CLEAR_SHOPPING_CART: {
      return { ...state, shoppingCart: initialState.shoppingCart };
    }
    case AppConstant.TOGGLE_ANNUAL: {
      return { ...state, isAnnually: payload };
    }
    case AppConstant.SHOW_SKIP_VERIFICATION_BUTTON: {
      return { ...state, allowToSkipPhoneVerification: payload };
    }

    case AppConstant.SET_AGREEMENT_DATA_FLAG: {
      return { ...state, shouldUpdateAgreement: payload };
    }

    case AppConstant.SET_COOKIES_BANNER: {
      return { ...state, showCookiesBanner: payload };
    }

    default: {
      return state;
    }
  }
}
