import { applyMiddleware, combineReducers, createStore } from 'redux';
import axiosMiddleware from 'redux-axios-middleware';
import { composeWithDevTools } from 'redux-devtools-extension';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import createSagaMiddleware from 'redux-saga';
import mySaga from '../sagas/main';
import client from '../services/api/client';
import middlewareConfig from '../services/api/middlewareConfig';
import createReducer from './reducers';

import entities from './reducers/entities';
import global from './reducers/global';

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['global', 'entities'],
};

const reduxSagaMonitorOptions = {};
const sagaMiddleware = createSagaMiddleware(reduxSagaMonitorOptions);
const axiosMiddlewareWithClient = axiosMiddleware(client, middlewareConfig);
const middlewares = [axiosMiddlewareWithClient, sagaMiddleware];

let enhancer = applyMiddleware(...middlewares);

// If Redux Dev Tools and Saga Dev Tools Extensions are installed, enable them
/* istanbul ignore next */
if (process.env.NODE_ENV !== 'production' && typeof window === 'object') {
  enhancer = composeWithDevTools(enhancer);
}

const blacklist = ['calculatedPrice', 'products', 'electronicSignment', 'socialNetworkLinksLoader'];

const entitiesPersistConfig = {
  key: 'entities',
  storage,
  blacklist,
};

const globalPersistConfig = {
  key: 'global',
  storage,
  whitelist: ['shoppingCart', 'userCountry', 'registrationValues'],
};

const rootReducer = combineReducers({
  entities: persistReducer(entitiesPersistConfig, entities),
  global: persistReducer(globalPersistConfig, global),
});

const persistedReducer = persistReducer(persistConfig, rootReducer);
export const store = createStore(persistedReducer, {}, enhancer);
export const persistor = persistStore(store);

// Extensions
store.runSaga = () => sagaMiddleware.run(mySaga);

export default function configureStore() {
  // Make reducers hot reloadable, see http://mxs.is/googmo
  /* istanbul ignore next */
  if (module.hot) {
    module.hot.accept('./reducers', () => {
      store.replaceReducer(createReducer(store));
    });
  }
  return store;
}
