import apiAction from '../apiActionCreator';

const type = 'UPDATE_USER_SOCIAL_NETWORK_LINKS';

const config = {
  url: 'v1/user/social-network-links',
  actionType: type,
  sendFormat: 'json',
  method: 'PUT',
};

export default apiAction(config);
