import uniqBy from 'lodash/uniqBy';
import planCombiner from '../../helpers/planCombiner';
import calculatePrice from '../../services/api/actions/calculatePrice';
import getBillingDetails from '../../services/api/actions/getBillingDetails';
import getCouponById from '../../services/api/actions/getCouponById';
import getCoupons from '../../services/api/actions/getCoupons';
import getMetaData from '../../services/api/actions/getMetaData';
import getTokenFinal from '../../services/api/actions/getTokenFinal';
import getUserDetails from '../../services/api/actions/getUserDetails';
import getUserElectronicSign from '../../services/api/actions/getUserElectronicSign';
import getUserPaymentMethods from '../../services/api/actions/getUserPaymentMethods';
import getUserRefferal from '../../services/api/actions/getUserRefferal';
import getUserSocialNetworkLinks from '../../services/api/actions/getUserSocialNetworkLinks';
import getUserSubscriptions from '../../services/api/actions/getUserSubscriptions';
import getWallets from '../../services/api/actions/getWallets';
import ordersPurchase from '../../services/api/actions/ordersPurchase';
import refreshToken from '../../services/api/actions/refreshToken';
import signUp from '../../services/api/actions/signUp';
import * as AppConstants from '../constants';

const products = require('../products.json');
const plans = require('../plans.json');

const prods = planCombiner(products?.data, plans?.data);

const initialState = {
  products: prods,
  userDetails: null,
  userRefferal: null,
  userSubscriptions: null,
  expectedSubscriptions: null,
  paymentMethods: null,
  billingDetails: null,
  coupons: null,
  wallets: null,
  calculatedPrice: null,
  electronicSignment: null,
  metaInfo: null,
  auditTrial: { id: '', step: 0 },
  socialNetworkLinks: null,
  socialNetworkLinksLoader: false,
};

export default function reducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case getUserDetails.type.success:
      return { ...state, userDetails: payload.data.data };

    case getUserSocialNetworkLinks.type.start:
      return { ...state, socialNetworkLinksLoader: true };

    case AppConstants.PUT_SOCIAL_LINKS:
      return { ...state, socialNetworkLinks: payload };

    case getUserSocialNetworkLinks.type.success:
      return { ...state, socialNetworkLinks: payload.data.data, socialNetworkLinksLoader: false };

    case getUserRefferal.type.success:
      return {
        ...state,
        userRefferal: {
          ...state.userRefferal,
          ...payload.data.data,
          showBanner: true,
          showBannerHeader: false,
          error: false,
        },
      };

    case getUserRefferal.type.error:
      return {
        ...state,
        userRefferal: { ...state.userRefferal, error: true },
      };

    case getUserSubscriptions.type.success:
      return { ...state, userSubscriptions: payload.data.data };
    case getUserPaymentMethods.type.success:
      // return { ...state, paymentMethods: payload.data };
      return { ...state };
    case getBillingDetails.type.success:
      return { ...state, billingDetails: payload.data };
    case getCoupons.type.success: {
      const couponList = payload.data.data?.filter((coupon) => coupon?.title !== 'INVISIBLE') || [];
      return { ...state, coupons: couponList };
    }
    case getWallets.type.success:
      return { ...state, wallets: payload.data.data };
    case getCouponById.type.success: {
      const coupons = uniqBy([...state.coupons, payload.data.data], 'id');
      return { ...state, coupons };
    }
    case calculatePrice.type.success:
      return { ...state, calculatedPrice: payload.data.data };
    case getUserElectronicSign.type.success:
      return { ...state, electronicSignment: payload.data.data };

    case AppConstants.SET_PRODUCTS:
      return { ...state, products: payload };

    // eslint-disable-next-line no-fallthrough
    case AppConstants.ADD_TO_CART:
    case AppConstants.REMOVE_FROM_CART:
    case AppConstants.CLEAR_SHOPPING_CART: {
      return { ...state, calculatedPrice: null };
    }

    case AppConstants.SAVE_REFERRAL_INFO:
      if (!payload) {
        return { ...state, userRefferal: null };
      }
      return {
        ...state,
        userRefferal: {
          ...state.userRefferal,
          ...payload,
        },
      };

    case AppConstants.SET_BANNER_REFFERAL:
      return {
        ...state,
        userRefferal: {
          ...state.userRefferal,
          showBanner: payload,
          showBannerHeader: !payload,
        },
      };

    case ordersPurchase.type.success:
      return {
        ...state,
        userRefferal: null,
        calculatedPrice: null,
      };

    case getTokenFinal.type.success:
      return {
        ...state,
        userRefferal: null,
      };

    case AppConstants.SET_EXPECTED_SUBSCRIPTIONS:
      return {
        ...state,
        expectedSubscriptions: payload,
      };

    case AppConstants.CLEAR_USER_DATA_ON_NEW_ENROLLMENT_INFO:
    case signUp.type.start:
      return {
        ...initialState,
        userRefferal: state.userRefferal,
      };

    case getMetaData.type.success:
      return {
        ...state,
        // ...initialState,
        // userDetails: state.userDetails,
        // userRefferal: state.userRefferal,
        // calculatedPrice: state.calculatedPrice,
        // electronicSignment: state.electronicSignment,
        // coupons: state.coupons,
        metaInfo: payload.data.data,
      };

    case AppConstants.LOG_OUT_USER:
    case refreshToken.type.error:
      return {
        ...initialState,
      };
    case AppConstants.SET_AUDIT_TRIAL:
      return {
        ...state,
        auditTrial: {
          ...state.auditTrial,
          id: payload.id,
          step: payload.step,
        },
      };
    default:
      return state;
  }
}
