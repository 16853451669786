import apiAction from '../apiActionCreator';

const type = 'EMAIL_VERIFICATION';

const config = {
  url: '/auth/send-otp-by-email',
  actionType: type,
  sendFormat: 'json',
  method: 'POST',
};

export default apiAction(config);
